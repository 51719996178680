import { message, Spin, Space } from "antd";
import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useAuth } from "../../hook/useAuth";
import { clearObjectStore } from "../../db/dexie-db/functions";
import { db } from "../../db/dexie-db/db";
import { importDataFromXml } from "../../api/Import/xml/import";
import dayjs from "dayjs";
// const db = openDataBase();

const ImportModal = (props) => {
  const { user } = useAuth();

  const [loadingText, setLoadingText] = useState("");

  useEffect(() => {
    if (props.isOpenUpdateDataModal) {
      setLoadingText("Завантаження даних");
      getData();
    }
  }, [props.isOpenUpdateDataModal]);

  const getData = async () => {
    try {
      const data = await getDataFromServer();
      if (data) {
        setLoadingText("Збереження даних...");
        saveDataToDataBase(data);
      }
    } catch (error) {
      setLoadingText("Помилка оновлення даних...");
      message.error("Помилка оновлення даних");
    }
  };

  const saveDataToDataBase = async (data) => {
    const products = data.products;
    const categories = data.categories;
    const clients = data.clients;
    const clientsCategories = data.clientsCategories;
    const price = data.price;
    const priceTypes = data.priceTypes;
    const firms = data.firms;
    const stocks = data.stocks;
    const storages = data.storages;
    const debts = data.debts;
    const docProperties = data.docProperties;
    const filters = data.filters;

    try {
      setLoadingText("Підготовка....");
      await clearIndexDb();
    } catch (error) {
      console.log(error);
      setLoadingText("");
      return message.error("[ERROR] - failed clear db");
    }

    try {
      setLoadingText("Збереження даних...");
      saveDataToIndexDb(
        products,
        categories,
        clients,
        clientsCategories,
        price,
        priceTypes,
        firms,
        stocks,
        storages,
        debts,
        docProperties,
        filters
      )
        .then((event) => {
          setTimeout(() => {
            props.setIsOpenUpdateDataModal(false);
            message.success("Дані оновлено!");
            localStorage.setItem("updated", dayjs());
          }, 500);
        })
        .catch((error) => {
          console.log("Помилка завантаження даних", error);
          message.error("Помилка завантаження даних");
          setLoadingText("Помилка оновлення, перевірте правильність вигрузки");
          throw error;
        });
    } catch (error) {
      console.log(error);
      alert("[ERROR] - failed to update data", error.message);
      setLoadingText("");
      throw error;
    }
  };

  const getDataFromServer = async () => {
    const data = await importDataFromXml(user.username);
    if (!data.products) {
      return message.error("Помилка завантаження товарів");
    }
    if (!data.categories) {
      return message.error("Помилка завантаження категорій");
    }
    if (!data.clients) {
      return message.error("Помилка завантаження клієнтів");
    }
    if (!data.price) {
      return message.error("Помилка завантаження цін");
    }
    if (!data.priceTypes) {
      return message.error("Помилка завантаження типів цін");
    }
    if (!data.firms) {
      return message.error("Помилка завантаження типів фірм");
    }
    if (!data.stocks) {
      return message.error("Помилка завантаження залишків");
    }
    if (!data.storages) {
      return message.error("Помилка завантаження складів");
    }
    if (!data.debts) {
      return message.error("Помилка завантаження боргів");
    }

    return data;
  };

  const clearIndexDb = async () => {
    return await Promise.all([
      clearObjectStore("Products"),
      clearObjectStore("Categories"),
      clearObjectStore("Clients"),
      clearObjectStore("ClientsCategories"),
      clearObjectStore("Price"),
      clearObjectStore("PriceTypes"),
      clearObjectStore("Firms"),
      clearObjectStore("Stocks"),
      clearObjectStore("Storages"),
      clearObjectStore("Debts"),
      clearObjectStore("DocProperties"),
      clearObjectStore("Filters"),
    ]);
  };

  const saveDataToIndexDb = async (
    products,
    categories,
    clients,
    clientsCategories,
    price,
    priceTypes,
    firms,
    stocks,
    storages,
    debts,
    docProperties,
    filters
  ) => {
    return await Promise.all([
      await db.Products.bulkPut(products),
      await db.Categories.bulkPut(categories),
      await db.Clients.bulkPut(clients),
      await db.ClientsCategories.bulkPut(clientsCategories),
      await db.Price.bulkPut(price),
      await db.PriceTypes.bulkPut(priceTypes),
      await db.Firms.bulkPut(firms),
      await db.Stocks.bulkPut(stocks),
      await db.Storages.bulkPut(storages),
      await db.Debts.bulkPut(debts),
      docProperties ? await db.DocProperties.bulkPut(docProperties) : "",
      filters ? await db.Filters.bulkPut(filters) : "",
    ]);
  };

  return (
    <>
      <Modal
        title="Оновлення даних"
        open={props.isOpenUpdateDataModal}
        onCancel={() => props.setIsOpenUpdateDataModal(false)}
        footer={null}
        keyboard={false}
      >
        <>
          <Space>
            <Spin spinning={true}></Spin> {loadingText}
          </Space>
        </>
      </Modal>
    </>
  );
};
export default ImportModal;
