import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ModalChooseClient from "../ModalChooseClient";
import {
  InputNumber,
  DatePicker,
  Form,
  message,
  Select,
  Input,
  Row,
  Col,
  Button,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { addRecord } from "../../../db/dexie-db/functions";
import dayjs from "dayjs";
import { TABLES } from "../../../_CONSTANTS/constants";
import { getDocumentId } from "../../../db/document-functions/document-functions";
import { useAuth } from "../../../hook/useAuth";

const IcoForm = (props) => {
  const { user } = useAuth();
  const location = useLocation();

  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  const documentSource = location.state?.document;
  const pageFrom = location.state?.from;

  const [input1Ref, setInput1Focus] = UseFocus();
  const [isModalChooseClientOpen, setIsModalChooseClientOpen] = useState(false);
  const [client, setClient] = useState(documentSource?.clientGuid);
  const [isDisabledClientField, setIsDisabledClientField] = useState(
    documentSource?.clientGuid ? true : false
  );

  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      dateTimeNow: dayjs(),
      // client: client?.guid,
    });
  }, []);

  useEffect(() => {
    if (documentSource) {
      form.setFieldsValue({
        dateTimeNow: dayjs(),
        sumIco: Math.abs(documentSource.sum),
      });

      setInput1Focus();
    }
  }, [client]);

  const onFinish = async (values) => {
    const sum = values.sumIco;
    const comment = values.comment;
    const date = dayjs().format("DD.MM.YY");

    const ico = {
      clientGuid: client,
      sum,
      date,
      comment,
      created: dayjs(),
      modified: dayjs(),
      exported: "false",
      sourceDocGuid: documentSource?.docGuid,
      documentId: getDocumentId(user.id),
    };

    try {
      await addRecord(TABLES.ICO, ico);
      message.success("Документ збережено!");
      navigate(pageFrom ? pageFrom : "/ico", { replace: true });
    } catch (error) {
      console.log(error);
      message.error("Помилка збереження документа");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Заповніть коректно поля!");
  };

  const onChangeClient = async (value) => {
    // form.setFieldsValue({
    //   client: value,
    // });
    setClient(value);
    setInput1Focus();
  };

  return (
    <>
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="on"
      >
        <Form.Item name="dateTimeNow">
          <DatePicker
            style={{ width: "100%" }}
            showTime
            size="large"
            disabled={true}
            format={"DD-MM-YYYY HH:mm:ss"}
          />
        </Form.Item>
        <Form.Item name="client">
          <Row>
            <Col span={20}>
              <Select
                size="large"
                onSelect={() => setInput1Focus()}
                style={{ width: "100%", textAlign: "left" }}
                disabled={isDisabledClientField}
                showSearch
                onChange={onChangeClient}
                value={client}
                placeholder="Контрагент"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={props.clients}
                defaultOpen={client ? false : true}
              />
            </Col>
            <Col span={4}>
              <Button
                disabled={isDisabledClientField}
                onClick={() => {
                  setIsModalChooseClientOpen(true);
                }}
                shape="default"
                size="large"
                icon={<SearchOutlined />}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name="sumIco">
          <InputNumber
            ref={input1Ref}
            style={{ width: "100%" }}
            parser={(value) => value.replace(",", ".")}
            precision={2}
            size="large"
            addonAfter="грн"
            type={"tel"}
            required={true}
          />
        </Form.Item>
        <Form.Item name="comment">
          <Input
            size="large"
            allowClear={true}
            style={{ width: "100%" }}
            placeholder="Примітка"
            // onChange={onChangeComment}
          />
        </Form.Item>
        <Form.Item>
          <Button size="large" type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      </Form>
      <ModalChooseClient
        isModalChooseClientOpen={isModalChooseClientOpen}
        setIsModalChooseClientOpen={setIsModalChooseClientOpen}
        setClient={setClient}
      ></ModalChooseClient>
    </>
  );
};

export default IcoForm;
