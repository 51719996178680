import { TABLES } from "../../_CONSTANTS/constants";
import { clearObjectStore } from "../../db/dexie-db/functions";
import { getServer } from "../../utils/getEnvironment";
import Cookies from "universal-cookie";

const server = getServer();

export async function loginNew(username, password, token, cameraDeviceId) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
      token,
      cameraDeviceId,
      action: "loginNew",
    }),
  });

  return await responce.json();
}

async function getUserByDeviceId(deviceId) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ deviceId, action: "getUserByDeviceId" }),
  });

  return await responce.json();
}

export const checkloginByDeviceId = async (deviceUniqueId) => {
  const authByDeviceId = await getUserByDeviceId(deviceUniqueId);
  if (authByDeviceId.status === "success") {
    return authByDeviceId;
  }
  return false;
};

export const getStoredToken = () => {
  const cookies = new Cookies();
  return cookies.get("_auth_token");
};

export async function logOut(username) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ username, action: "logout" }),
  });

  return await responce.json();
}

export async function autoLogin(user, token) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ user, token, action: "autoLogin" }),
  });

  return await responce.json();
}

export const checkToRemoveDocuments = async (username) => {
  const prevUsername = localStorage.getItem("currentUser");

  if (prevUsername && prevUsername !== username) {
    await clearObjectStore(TABLES.ORDERS);
    await clearObjectStore(TABLES.ICO);
    // await clearObjectStore(TABLES.INVOICES);
  }
};
