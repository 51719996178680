import React from "react";
import { Table } from "antd";
import { getRecord } from "../../db/dexie-db/functions";
import { CheckOutlined } from "@ant-design/icons";

const Products = (props) => {
  const columns = [
    {
      title: "Назва",
      dataIndex: "title",
      width: "50%",
      sorter: (a, b) => a.title - b.title,
      render: (_, record) => {
        if (props?.showBarcodesInTree) {
          return (
            <span>
              {record.title}
              <br></br>
              <i style={{ fontSize: "small" }}>
                {record.barcodes.map((barcode) => {
                  return (
                    <>
                      <span>{barcode}</span>
                      <br />
                    </>
                  );
                })}
              </i>
            </span>
          );
        }
        return record.title;
      },
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "25%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "Зал",
      dataIndex: "qty",
      width: "15%",
      render: (_, record) => {
        return record.stockQty;
      },
    },
    {
      title: <CheckOutlined />,
      dataIndex: "enteredQty",
      width: "15%",
      render: (_, record) => {
        return record.enteredQty ? (
          <strong>{record.enteredQty}</strong>
        ) : (
          record.enteredQty
        );
      },
      className: "enteredQty",
    },
  ];

  async function onRowClick(record) {
    try {
      const currentOrderEnteredProduct = await getRecord(
        props.dbTable,
        record.guid
      );

      const editedOrderEnteredProduct = props?.products.find(
        (editedOrderProduct) => editedOrderProduct.guid === record.guid
      );

      let alreadyEntered = {};
      if (currentOrderEnteredProduct) {
        alreadyEntered = currentOrderEnteredProduct;
      } else {
        alreadyEntered = editedOrderEnteredProduct;
      }

      const enteredQuantity = Number(alreadyEntered.qty);
      if (enteredQuantity) {
        record.qty = enteredQuantity;
      }
    } catch (error) {
      // console.log(error);
    }
    const product = record;

    props.setProductKey({ product });
    props.setIsModalOpenEnterQty(true);
  }

  return (
    <>
      <Table
        size={"small"}
        loading={props.loading}
        pagination={{
          position: ["bottomCenter"],
          pageSize: props.disablePagination ? 9999 : 10,
          hideOnSinglePage: true,
        }}
        scroll={{
          // x: "max-content",
          y: window.screen.height - 200,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => onRowClick(record),
          };
        }}
        rowClassName={(record, index) => {
          return record.alreadyEntered ? "alreadyEntered" : "";
        }}
        columns={columns}
        dataSource={props.productsToTable}
        tableLayout="fixed"
      />
    </>
  );
};

export default Products;
