import { Divider, Space, Switch, message } from "antd";
import React from "react";
import { getProductsFromPrevOrders } from "../../db/document-functions/document-functions";
import {
  getProductsByFiltersIds,
  getProductsToTable,
} from "../../db/document-functions/functions";
import { getUniqueArrayOfObjects } from "../../utils/arrayFunctions";
import { TABLES } from "../../_CONSTANTS/constants";

export default function Settings({
  isCheckedShowProductsByStock,
  onChangeShowByStock,
  isCheckedDisablePagination,
  onChangeDisablePagination,
  setProductsToTable,
  client,
  setLoading,
  isCheckedProductsFromPrevOrdersOnly,
  setIsCheckedProductsFromPrevOrdersOnly,
  productsToTable,
  priceTypeGuid,
  filters,
  setFilters,
  onChangeShowBarcodesInTree,
  showBarcodesInTree,
}) {
  const onChangeProductsFromPrevOrdersOnly = async (value) => {
    const productsFromPrevOrders = await getProductsFromPrevOrders(client);

    if (!productsFromPrevOrders.length)
      return message.error("Немає даних з попередніх замовлень!");
    setLoading(false);
    const productsByFilters = await getProductsByFilters(filters);
    const allProducts = [...productsByFilters, ...productsFromPrevOrders];

    const productsToTable = await getProductsToTable(
      allProducts,
      priceTypeGuid,
      TABLES.CURRENT_ORDER
    );

    setProductsToTable(
      productsToTable.map((prod) => {
        return { ...prod, enteredQty: 0, qty: 0 };
      })
    );

    setIsCheckedProductsFromPrevOrdersOnly(value);
  };

  const onChangeFilter = async (filterId, checked) => {
    const currentFilter = filters.find((filt) => filt.id === filterId);
    currentFilter.checked = checked;
    const newFilters = filters.map((filt) => {
      if (filt.id === filterId) {
        return currentFilter;
      }
      return filt;
    });

    setFilters([...newFilters]);

    const productsByFilters = await getProductsByFilters(newFilters);
    const productsToTable = await getProductsToTable(
      productsByFilters,
      priceTypeGuid,
      TABLES.CURRENT_ORDER
    );
    setLoading(false);
    let productsFromPrevOrders;
    if (isCheckedProductsFromPrevOrdersOnly) {
      productsFromPrevOrders = await getProductsFromPrevOrders(client);
      if (!productsFromPrevOrders.length) {
        message.error("Немає даних з попередніх замовлень!");
      } else {
        productsFromPrevOrders = productsFromPrevOrders.map((prod) => {
          return { ...prod, enteredQty: 0, qty: 0 };
        });
      }
    }

    setProductsToTable(
      productsFromPrevOrders
        ? getUniqueArrayOfObjects(
            [...productsToTable, ...productsFromPrevOrders],
            "guid"
          )
        : getUniqueArrayOfObjects(productsToTable, "guid")
    );
  };

  const getProductsByFilters = async (productsFilters) => {
    const activeFilters = productsFilters.filter((filt) => filt.checked);
    const productsByFilters = await getProductsByFiltersIds(
      activeFilters.map((filt) => filt.id)
    );
    return productsByFilters;
  };

  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={isCheckedShowProductsByStock}
            size="default"
            onChange={onChangeShowByStock}
          />
          По залишках
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={isCheckedDisablePagination}
            size="default"
            onChange={onChangeDisablePagination}
          />
          Без сторінок
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={showBarcodesInTree}
            size="default"
            onChange={onChangeShowBarcodesInTree}
          />
          Показувати штрих-коди
        </Space>
        <Divider style={{ margin: "5px 0px" }} children={"Фільтри"}></Divider>
        <Space direction="horizontal">
          <Switch
            checked={isCheckedProductsFromPrevOrdersOnly}
            size="default"
            onChange={onChangeProductsFromPrevOrdersOnly}
          />
          Товари з попередніх замовлень
        </Space>
        {filters.map((filter) => {
          return (
            <Space direction="horizontal">
              <Switch
                checked={filters.find((filt) => filt.id === filter.id).checked}
                onChange={(value) => onChangeFilter(filter.id, value)}
              />
              {filter.title}
            </Space>
          );
        })}
      </Space>
    </>
  );
}
