import React, { useEffect, useState } from "react";
import { Col, Row, Tree } from "antd";
import { listToTree } from "../../utils/list-to-tree";
import {
  getAllRecords,
  getRecord,
  getRecords,
} from "../../db/dexie-db/functions";
import { compareByTitle } from "../../utils/arrayFunctions";
import { setKeysAndIndexToArray } from "../../db/document-functions/document-functions";
import { TABLES } from "../../_CONSTANTS/constants";
import { getProductsToTable } from "../../db/document-functions/functions";
const { DirectoryTree } = Tree;

const Categories = (props) => {
  const [categories, setCategories] = useState();

  useEffect(() => {
    async function getParentCategories() {
      const categories = await getAllRecords(TABLES.CATEGORIES);
      // console.log(categories);
      const categoriesTree = listToTree(categories.sort(compareByTitle));

      setCategories(categoriesTree);
    }
    getParentCategories();
  }, []);

  const onSelect = async (keys, info) => {};

  const onExpand = async (keys, info) => {
    if (!info.expanded) {
      return;
    }
    let key = keys.slice(-1)[0];

    let productsFromCategory = await getRecords(
      TABLES.PRODUCTS,
      "parentCode",
      key
    );
    if (props.showProductsByStock) {
      productsFromCategory = productsFromCategory.filter(
        (product) => product.stockQty !== 0
      );
    }

    if (productsFromCategory.length > 0) {
      const productsToTable = await getProductsToTable(
        productsFromCategory,
        props.priceTypeGuid,
        props.dbTable
      );

      const sortedProducts = productsToTable.sort(compareByTitle);
      const productsWithKeys = setKeysAndIndexToArray(sortedProducts);

      resetFilters();

      if (props?.setIsCheckedProductsFromPrevOrdersOnly)
        props?.setIsCheckedProductsFromPrevOrdersOnly(false);

      props.setProductsToTable(productsWithKeys);
      props.setLoading(false);
      props.setActiveKey("2");
    } else {
      // message.info("В даній категорії немає товарів");
    }
  };

  const resetFilters = () => {
    if (!props?.setFilters || !props?.filters) return;
    props.setFilters(
      props.filters.map((filter) => {
        return { ...filter, checked: false };
      })
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <DirectoryTree
            style={{ textAlign: "left" }}
            multiple
            defaultExpandParent={false}
            onSelect={onSelect}
            onExpand={onExpand}
            treeData={categories}
          />
        </Col>
      </Row>
    </>
  );
};

export default Categories;
