import { Table, Row, Col, Button, Input, Space } from "antd";
import React, { useState, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import ModalActions from "./ModalActions";
import dayjs from "dayjs";
import ModalCreateIco from "./ModalCreateIco";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default function DebtsTable(props) {
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);

  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [isModalCreateIcoOpen, setIsModalCreateIcoOpen] = useState(false);

  const [debtData, setDebtData] = useState({});

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size={"large"}
          ref={searchInput}
          placeholder={`Введіть текст`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Очистити
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const onChildrenRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setDebtData({ document: record });
        setIsModalActionsOpen(true);
      },
    };
  };

  const columns = [
    {
      title: "Клієнт",
      dataIndex: "clientName",
      sorter: (a, b) => {
        return a.clientName.localeCompare(b.clientName);
      },
      key: "clientName",
      width: "75%",
      ...getColumnSearchProps("clientName"),
    },
    {
      title: "Борг",
      dataIndex: "debtValue",
      sorter: (a, b) => {
        return a.debtValue - b.debtValue;
      },
      key: "debtValue",
      width: "25%",
    },
  ];

  const expandedRowRender = (row) => {
    const childColumns = [
      {
        title: "Дата",
        dataIndex: "docDate",
        key: "docDate",
        render: (_, record) => {
          const created = dayjs(record.docDate, "DD.MM.YYYY HH:mm:ss").format(
            "DD.MM HH:mm"
          );
          return created;
        },
      },
      {
        title: "Документ",
        dataIndex: "docName",
        key: "docName",
      },
      {
        title: "Сума",
        dataIndex: "docDebt",
        key: "docDebt",
      },
    ];

    const data = [...row.documents];
    return (
      <Table
        columns={childColumns}
        onRow={onChildrenRow}
        dataSource={data}
        pagination={false}
      />
    );
  };

  return (
    <>
      <Row style={{ justifyContent: "center" }}>
        <Col span={24}>
          <Table
            loading={props.loading}
            pagination={{ position: ["bottomCenter"], pageSize: 15 }}
            // onRow={onRowAction}
            columns={columns}
            dataSource={props.debts}
            // onChange={onChange}
            expandable={{
              expandedRowRender,
              expandRowByClick: true,
              // defaultExpandedRowKeys: defaulExpandedRow,
            }}
            size="small"
            tableLayout="fixed"
          />
          <ModalActions
            isModalActionsOpen={isModalActionsOpen}
            setIsModalActionsOpen={setIsModalActionsOpen}
            debtData={debtData}
            setIsModalCreateIcoOpen={setIsModalCreateIcoOpen}
          ></ModalActions>
          <ModalCreateIco
            isModalCreateIcoOpen={isModalCreateIcoOpen}
            setIsModalCreateIcoOpen={setIsModalCreateIcoOpen}
            documentSource={debtData.document}
          ></ModalCreateIco>
        </Col>
      </Row>
    </>
  );
}
