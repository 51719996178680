import { Modal, Skeleton, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getRecordByKeyValue, getRecords } from "../../db/dexie-db/functions";

export default function InfoProductModal(props) {
  const [currentProductStockData, setCurrentProductStockData] = useState([]);
  const [currentProductPriceData, setCurrentProductPriceData] = useState([]);
  const [isSkeletonActive, setIsSkeletonActive] = useState(true);

  const product = props?.productToModal;

  useEffect(() => {
    setIsSkeletonActive(true);
    if (product) {
      getData(product);
    }
  }, [product]);

  const getData = async (product) => {
    try {
      const stocks = await getRecords("Stocks", "productGuid", product.guid);
      const prices = await getRecords("Price", "productGuid", product.guid);
      const stocksFull = await getFullStocks(stocks);
      const priceFull = await getFullPrice(prices);

      setCurrentProductStockData(stocksFull);
      setCurrentProductPriceData(priceFull);
      setIsSkeletonActive(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getFullStocks = async (stocks) => {
    return await Promise.all(
      stocks.map(async (stock) => {
        try {
          const storage = await getRecordByKeyValue("Storages", {
            guid: stock.storageGuid,
          });
          if ((stock.storageGuid = storage.guid)) {
            return { ...stock, storageName: storage.name };
          }
        } catch (error) {}
      })
    );
  };

  const getFullPrice = async (prices) => {
    return await Promise.all(
      prices.map(async (price) => {
        try {
          const priceType = await getRecordByKeyValue("PriceTypes", {
            guid: price.priceTypeGuid,
          });
          if ((price.priceTypeGuid = priceType.guid)) {
            return { ...price, priceTypeName: priceType.name };
          }
        } catch (error) {}
      })
    );
  };

  const columnsPriceTable = [
    {
      title: "Тип ціни",
      dataIndex: "priceTypeName",
      width: "75%",
      sorter: (a, b) => a.title - b.title,
    },
    {
      title: "Ціна",
      dataIndex: "priceValue",
      sorter: (a, b) => a.priceValue - b.priceValue,
      width: "25%",
    },
  ];

  const columnsStocksTable = [
    {
      title: "Склад",
      dataIndex: "storageName",
      width: "75%",
      sorter: (a, b) => a.title - b.title,
    },
    {
      title: "К-ть",
      dataIndex: "productStock",
      sorter: (a, b) => a.storageName - b.storageName,
      width: "25%",
    },
  ];

  return (
    <>
      <Modal
        title={product?.title}
        open={props.isModalInfoProductOpen}
        footer={null}
        style={{ top: 10 }}
        onCancel={() => props.setIsModalInfoProductOpen(false)}
      >
        {isSkeletonActive ? (
          <Skeleton active={isSkeletonActive} />
        ) : (
          <>
            <Table
              dataSource={currentProductPriceData}
              columns={columnsPriceTable}
              size="small"
              tableLayout="fixed"
              pagination={{ pageSize: 99, hideOnSinglePage: true }}
            ></Table>
            {currentProductStockData.length > 0 ? (
              <>
                <br />
                <Table
                  dataSource={currentProductStockData}
                  columns={columnsStocksTable}
                  size="small"
                  tableLayout="fixed"
                ></Table>
              </>
            ) : (
              ""
            )}

            {/* Залишки:
            <ul>
              {currentProductStockData.map((item) => (
                <li key={item.storageGuid}>
                  {item.storageName} - {item.productStock}
                </li>
              ))}
            </ul>
            Ціни:
            <ul>
              {currentProductPriceData.map((item) => (
                <li key={item.priceTypeGuid}>
                  {item.priceTypeName} - {item.priceValue}
                </li>
              ))}
            </ul> */}
          </>
        )}
      </Modal>
    </>
  );
}
