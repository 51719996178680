import React, { useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { ADMIN_DATE_FORMAT, GOOGLE_MAP_API_KEY } from "../_CONSTANTS/constants";
import { Col, FloatButton, Row, Skeleton, message } from "antd";
import dayjs from "dayjs";
import { getAgentsDocumentsCoordinates } from "../api/Admin/api";
import MapFilter from "../admin-components/MapFilter";
import MyMarker from "../admin-components/MapComponents/MyMarker";
import { TableOutlined, UserOutlined } from "@ant-design/icons";
import RecordsSteps from "../admin-components/RecordsSteps";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const centerMap = {
  lat: 48.321,
  lng: 25.769,
};

const container = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "lightgray",
  position: "relative",
};

const defaultDate = dayjs().format(ADMIN_DATE_FORMAT);

export default function MapsPage() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  const [dates, setDates] = useState([defaultDate, defaultDate]);
  const [agents, setAgents] = useState();
  const [selectedAgent, setSelectedAgent] = useState();
  const [documents, setDocuments] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [isRecordsTableOpen, setIsRecordsTableOpen] = useState(false);
  const [isMapFilterOpen, setIsMapFilterOpen] = useState(true);
  const [center, setCenter] = useState(centerMap);
  const [isLoading, setIsLoading] = useState(false);

  if (!isLoaded) {
    return <Skeleton></Skeleton>;
  }

  const startDoc = {
    client_name: "Початок",
    date_time: "2024-04-09 07:22:00",
    document_id: "startPoint",
    coordinates: '"48.540637,24.9927451"',
  };

  const endDoc = {
    client_name: "Кінець",
    date_time: "2024-04-09 21:22:00",
    document_id: "endPoint",
    coordinates: '"49.5484029,25.5564696"',
  };

  const updateMap = async () => {
    setDocuments([]);
    setIsLoading(true);
    if (!selectedAgent) return message.error("Виберіть торгового");
    const documents = await getAgentsDocumentsCoordinates(
      selectedAgent.id,
      dates
    );

    documents.unshift(startDoc);
    documents.push(endDoc);

    const markers = documents.map((doc, index) => {
      const coordinatesString = JSON.parse(doc.coordinates);
      const coordinates = coordinatesString.split(",");
      documents[index].coordinates = {
        lat: +coordinates[0],
        lng: +coordinates[1],
      };

      return {
        lat: +coordinates[0],
        lng: +coordinates[1],
        userId: doc.user_id,
        clientName: doc.client_name,
        key: index,
        documentId: doc.document_id,
      };
    });

    if (!markers.length) {
      setIsLoading(false);
      message.info("Немає даних по вибраних агентах!");
      setDocuments([]);
      return setMarkers([]);
    }
    setDocuments(documents);

    setMarkers(markers);
    setIsRecordsTableOpen(true);
    setIsLoading(false);
  };

  const onClickMarker = (marker) => {
    console.log(marker);
  };

  const handleOnLoad = (map) => {
    // const bounds = new google.maps.LatLngBounds();
    // markers.forEach(({ position }) => bounds.extend(position));
    // map.fitBounds(bounds);
  };

  const onTableRecordClick = (record) => {
    const activeMarker = markers.find(
      (marker) => marker.documentId === record.documentId
    );
    console.log(activeMarker);
    setCenter({ lat: activeMarker.lat, lng: activeMarker.lng });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <div style={container}>
            <MapFilter
              agents={agents}
              setAgents={setAgents}
              updateMap={updateMap}
              selectedAgent={selectedAgent}
              setSelectedAgent={setSelectedAgent}
              dates={dates}
              setDates={setDates}
              isMapFilterOpen={isMapFilterOpen}
              setIsMapFilterOpen={setIsMapFilterOpen}
            ></MapFilter>

            <GoogleMap
              options={{}}
              mapContainerStyle={containerStyle}
              center={center}
              zoom={11}
              onLoad={handleOnLoad}
            >
              <RecordsSteps
                isLoading={isLoading}
                selectedAgent={selectedAgent}
                onRowClick={onTableRecordClick}
                documents={documents}
                setIsRecordsTableOpen={setIsRecordsTableOpen}
                isRecordsTableOpen={isRecordsTableOpen}
              ></RecordsSteps>
              {markers &&
                markers.map((marker) => {
                  return (
                    <>
                      <MyMarker
                        marker={marker}
                        onClickMarker={onClickMarker}
                      ></MyMarker>
                    </>
                  );
                })}
            </GoogleMap>
          </div>
        </Col>
      </Row>
      <FloatButton.Group
        shape="square"
        style={{
          left: 24,
        }}
      >
        <FloatButton
          icon={<UserOutlined />}
          type="primary"
          onClick={() => setIsMapFilterOpen(!isMapFilterOpen)}
        />
        <FloatButton
          icon={<TableOutlined />}
          type="primary"
          onClick={() => setIsRecordsTableOpen(!isRecordsTableOpen)}
        />
      </FloatButton.Group>
    </>
  );
}
