import { Modal, Space, Switch, Tabs } from "antd";
import React, { useState } from "react";
import {
  DatabaseOutlined,
  OrderedListOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import ClientCategories from "../clients/ClientCategories";
import Clients from "../clients/Clients";

export default function ModalChooseClient(props) {
  const [clientsToTable, setClientsToTable] = useState([]);
  const [activeKey, setActiveKey] = useState();
  const [loading, setLoading] = useState(true);
  const [isCheckedDisablePagination, setIsCheckedDisablePagination] = useState(
    localStorage.getItem("disableClientPagination") === "true" ?? true
  );

  const changeKey = (key) => {
    setActiveKey(key);
  };

  const onChangeDisablePagination = (value) => {
    localStorage.setItem("disableClientPagination", value);
    setIsCheckedDisablePagination(value);
  };

  const handleCancel = () => {
    props.setIsModalChooseClientOpen(false);
  };

  const items = [
    {
      label: (
        <span>
          <DatabaseOutlined />
          Категорії
        </span>
      ),
      key: "1",
      children: (
        <ClientCategories
          //   priceTypeGuid={props.priceType}
          // document={props.document}
          // categories={categories}
          setClientsToTable={setClientsToTable}
          setLoading={setLoading}
          setActiveKey={setActiveKey}
          //   showProductsByStock={isCheckedShowProductsByStock}
        ></ClientCategories>
      ),
    },
    {
      label: (
        <span>
          <OrderedListOutlined />
          Клієнти
        </span>
      ),
      key: "2",
      children: (
        <Clients
          // action={props.action}
          // dbTable={props.dbTable}
          setIsModalChooseClientOpen={props.setIsModalChooseClientOpen}
          loading={loading}
          clientsToTable={clientsToTable}
          onChangeClient={props.onChangeClient}
          disablePagination={isCheckedDisablePagination}
        ></Clients>
      ),
    },
    {
      label: <SettingOutlined />,
      key: "3",
      children: (
        <>
          <Space direction="vertical">
            <Space direction="horizontal">
              <Switch
                checked={isCheckedDisablePagination}
                size="default"
                onChange={onChangeDisablePagination}
              />
              Без сторінок
            </Space>
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        style={{
          top: 10,
        }}
        bodyStyle={{
          padding: "0px",
        }}
        footer={null}
        title={"Виберіть клієнта"}
        onCancel={handleCancel}
        open={props.isModalChooseClientOpen}
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={changeKey}
          items={items}
        ></Tabs>
      </Modal>
    </>
  );
}
