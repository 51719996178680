import {
  Button,
  Divider,
  InputNumber,
  Modal,
  Select,
  Space,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { getAllRecords } from "../../db/dexie-db/functions";
import { LOCAL_STORAGE_FIELDS, TABLES } from "../../_CONSTANTS/constants";
import { useTheme } from "../../hook/useTheme";
import { deleteOldServicesDocuments } from "../../db/document-functions/document-functions";

export default function SettingsModal(props) {
  const darkMode =
    localStorage.getItem("useDarkMode") === "true" ? true : false;

  const daysForStoringDocuments = +localStorage.getItem(
    LOCAL_STORAGE_FIELDS.DAYS_FOR_STORING_DOCUMENTS
  );

  const updateDaysForStingDocuments = async (days) => {
    setDays(days);
    localStorage.setItem(LOCAL_STORAGE_FIELDS.DAYS_FOR_STORING_DOCUMENTS, days);
  };

  const { setUseDarkMode } = useTheme();
  const [days, setDays] = useState(
    daysForStoringDocuments ? daysForStoringDocuments : 21
  );

  const [storages, setStorages] = useState([]);
  const [firms, setFirms] = useState([]);
  const [defaultStorage, setDefaultStorage] = useState(
    localStorage.getItem(LOCAL_STORAGE_FIELDS.DEFAULT_STORAGE)
  );
  const [isCheckedAutoDeleteDocuments, setIsCheckedAutoDeleteDocuments] =
    useState(
      localStorage.getItem(LOCAL_STORAGE_FIELDS.AUTO_DELETE_DOCUMENTS) ===
        "true"
    );
  const [defaultFirm, setDefaultFirm] = useState(
    localStorage.getItem(LOCAL_STORAGE_FIELDS.DEFAULT_FIRM)
  );

  const onChangeStorage = (storageGuid) => {
    localStorage.setItem("defaultStorageGuid", storageGuid);
    setDefaultStorage(storageGuid);
  };

  const onChangeFirm = (firmGuid) => {
    localStorage.setItem("defaultFirmGuid", firmGuid);
    setDefaultFirm(firmGuid);
  };

  useEffect(() => {
    getStorages();
    getFirms();
  }, []);

  const onChangeUseDarkMode = async (value) => {
    console.log(value);
    // toggleThemeMode(value);
    setUseDarkMode(value);
    localStorage.setItem("useDarkMode", value);
  };

  const getStorages = async () => {
    try {
      const storagesFromDb = await getAllRecords(TABLES.STORAGES);
      const storages = storagesFromDb.map((storage) => {
        return (storage = {
          label: storage.name,
          value: storage.guid,
        });
      });
      setStorages(storages);
    } catch (error) {
      message.error("Помилка отримання складів");
    }
  };

  const getFirms = async () => {
    try {
      const firmsFromDb = await getAllRecords(TABLES.FIRMS);
      const firms = firmsFromDb.map((firm) => {
        return (firm = {
          label: firm.name,
          value: firm.guid,
        });
      });
      setFirms(firms);
    } catch (error) {
      message.error("Помилка отримання фірм");
    }
  };

  const onchangeAutoDeleteDocuments = (value) => {
    localStorage.setItem(LOCAL_STORAGE_FIELDS.AUTO_DELETE_DOCUMENTS, value);
    setIsCheckedAutoDeleteDocuments(value);
  };

  const handleRemoveOldDocuments = async () => {
    await deleteOldServicesDocuments(days);
  };

  return (
    <>
      <Modal
        title={"Налаштування"}
        open={props.isModalSettingsOpen}
        setIsOpen={props.setIsModalSettingsOpen}
        footer={null}
        closable={true}
        style={{ top: "5px" }}
        onCancel={() => props.setIsModalSettingsOpen(false)}
      >
        <div>Склад</div>
        <Select
          style={{ width: "100%" }}
          disabled={props.disabledFields}
          value={defaultStorage}
          aria-required={true}
          placeholder="Склад"
          optionFilterProp="children"
          onSelect={onChangeStorage}
          onChange={onChangeStorage}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          options={storages}
        />
        <Divider style={{ margin: "10px" }}></Divider>
        <div>Фірма</div>
        <Select
          style={{ width: "100%" }}
          disabled={props.disabledFields}
          value={defaultFirm}
          aria-required={true}
          placeholder="Фірма"
          optionFilterProp="children"
          onSelect={onChangeFirm}
          onChange={onChangeFirm}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          options={firms}
        />
        <Divider style={{ margin: "10px" }}></Divider>
        <Space>
          <Switch
            checked={darkMode}
            size="default"
            onChange={(value) => onChangeUseDarkMode(value)}
          />
          Нічний режим
        </Space>
        <br></br>
        <br></br>
        <Space>
          <Switch
            checked={isCheckedAutoDeleteDocuments}
            size="default"
            onChange={(value) => onchangeAutoDeleteDocuments(value)}
          />
          Автовидалення документів
        </Space>
        <br></br>
        <br></br>
        <Space>
          <p style={{ margin: "0px" }}>Зберігати документи:</p>
          <InputNumber
            addonAfter={<span style={{ padding: 10 }}>днів</span>}
            onChange={(e) => {
              updateDaysForStingDocuments(e);
            }}
            max={50}
            min={3}
            value={days}
            type="tel"
          ></InputNumber>
        </Space>
        {!isCheckedAutoDeleteDocuments ? (
          <>
            <br></br>
            <br></br>
            <Space>
              <Button onClick={handleRemoveOldDocuments}>
                Видалити документи
              </Button>
            </Space>
          </>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
}
